import React, {useEffect} from 'react'
import {FullScreenLoader} from '@modifi/ui'
import * as PropTypes from 'prop-types'
import {InferPropTypes} from '@modifi/typescript-utils'

const propTypes = {
  redirect: PropTypes.func.isRequired,
  to: PropTypes.func.isRequired,
}
const defaultProps = {}

export type RedirectWithLoaderProps = InferPropTypes<typeof propTypes, typeof defaultProps>

const RedirectWithLoader: React.FC<RedirectWithLoaderProps> = ({to, redirect}) => {
  useEffect(() => {
    redirect(to)
  }, [redirect, to])

  return <FullScreenLoader />
}

RedirectWithLoader.propTypes = propTypes

RedirectWithLoader.defaultProps = defaultProps

export default RedirectWithLoader
