import {connect} from '@modifi/redux'
import {aReplace} from '@modifi/router'
import RedirectWithLoader from './RedirectWithLoader'

const mapStateToProps = (): any => ({})

const mapDispatchToProps = {
  redirect: aReplace,
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectWithLoader)
